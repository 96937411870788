import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Layout from '../components/layout';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';
import useTimeout from '../components/util/useTimeout';

/**
 * CAHPSCompare page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <CAHPSCompare location={string} />
 */
const CAHPSCompare = ({ location }) => {
  useTimeout(() => {
    window.location.href =
      'https://public.tableau.com/views/2024MACAHPSCompare/2024MACAHPSCompare?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link';
  }, 1500);

  return (
    <Layout location={location}>
      <article
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyStart,
          base.w100,
          main.mt60,
          main.mb60,
          main.postLegal,
          main.postSingle,
          main.px60
        )}
      >
        <h1 className={cx(base.mb5, base.tc, theme.colorDark)}>
          Redirecting Tableau CAHPS Compare...
        </h1>
      </article>
    </Layout>
  );
};

CAHPSCompare.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default CAHPSCompare;
